import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import { Link, graphql } from "gatsby";
import { css } from "@emotion/react";
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
deckDeckGoHighlightElement();

export default function BlogPost({ data }) {
	const post = data.markdownRemark;
	return (
		<>
			<SEO
				title={post.frontmatter.title}
				description={post.excerpt}
				image="https://i.imgur.com/Nyv2NBO.jpg"
			/>
			<Layout>
				<h2
					css={css`
						margin-bottom: 0;
					`}
				>
					{post.frontmatter.title}
				</h2>
				<p>
					{post.frontmatter.date} / {post.timeToRead} minute read
				</p>
				<div dangerouslySetInnerHTML={{ __html: post.html }} />
				<div
					css={css`
						float: right;
						font-size: 0.8rem;
					`}
				>
					<Link to="/blog">back to all posts</Link>
				</div>
			</Layout>
		</>
	);
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				date(formatString: "DD MMMM, YYYY")
			}
			excerpt
			timeToRead
		}
	}
`;
